import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import formatTitle from "../../components/common/formatTitle";
import Highlight from "./highlight";
import HighlightMobile from "./highlightMobile";

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
    marginTop: theme.spacing(4),
  },
  withBg: {
    backgroundColor: theme.palette.background.medium,
  },
  bigPaddingHoriz: {
    paddingRight: theme.spacing(20),
    paddingLeft: theme.spacing(20),
  },
  leftAligned: {
    textAlign: "left",
  },
  warning: {
    color: theme.palette.primary.light,
    marginTop: 4,
  },
  bold: {
    fontWeight: 1000,
  },
  content: {
    marginTop: theme.spacing(1),
  },
  enLight: {
    color: theme.palette.primary.light,
  },
}));

const Highlights = ({ highlights, btnLabel, title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  if (!highlights) return null;
  return (
    <Grid
      item
      className={clsx({
        [classes.paddingRegular]: matches,
        [classes.paddingRegularMobile]: !matches,
        [classes.withBg]: false,
        [classes.bigPaddingHoriz]: matches,
      })}
      container
      direction="column"
    >
      <Grid item>
        <Typography variant="h1">{formatTitle(title)}</Typography>
      </Grid>
      <Grid
        item
        container
        direction={matches ? "row" : "column"}
        justify={matches ? "center" : "flex-start"}
        spacing={4}
        style={{ marginTop: "2%" }}
      >
        {highlights.map((f, idx) =>
          matches ? (
            <Highlight key={idx} highlight={f} btnLabel={btnLabel} />
          ) : (
            <HighlightMobile
              key={idx}
              highlight={f}
              btnLabel={btnLabel}
              even={idx % 2 === 0}
            />
          )
        )}
      </Grid>
    </Grid>
  );
};

Highlights.defaultProps = {
  iconOnSameRow: true,
  noIcon: false,
  noPadding: false,
};

Highlights.propTypes = {
  highlights: PropTypes.array.isRequired,
  iconOnSameRow: PropTypes.bool.isRequired,
  noIcon: PropTypes.bool.isRequired,
  noPadding: PropTypes.bool.isRequired,
};

export default Highlights;
