import React from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import ArrowButton from "../../components/common/ArrowButton";

const useStyles = makeStyles((theme) => ({
  imgRounded: {
    borderRadius: "4px",
  },
  title: {
    fontWeight: "1000",
    marginTop: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(4),
  },
  enLight: {
    color: theme.palette.primary.light,
  },
  top: {
    marginTop: 0,
  },
  arrow: {
    color: theme.palette.primary.dark,
    marginTop: "2px",
  },
  arrowBtnLabel: {
    color: theme.palette.primary.dark,
  },
  imgMob: {
    textAlign: "center",
  },
}));

const HighlightMobile = ({ highlight, btnLabel, even }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  if (!highlight) return null;
  return (
    <Grid
      item
      container
      direction={matches ? "column" : "row"}
      alignItems="stretch"
      justify={matches ? "flex-start" : "space-between"}
      style={{
        backgroundColor: even
          ? theme.palette.background.default
          : theme.palette.background.medium,
      }}
    >
      <Grid item xs={12} sm={3}>
        <img
          src={highlight.image}
          alt="example1"
          width="100%"
          height="auto"
          className={classes.imgRounded}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography variant="body2" className={classes.top}>
          <span className={classes.enLight}>{highlight.language}</span>
          <br />
          {highlight.date}
        </Typography>
        <Typography variant="h6" className={classes.title}>
          {highlight.title}
        </Typography>
        <div style={{ textAlign: "left" }}>
          <ArrowButton
            href={highlight.url}
            color="primary"
            label={btnLabel}
            target="__blank"
          />
        </div>
      </Grid>
    </Grid>
  );
};

HighlightMobile.propTypes = {
  highlight: PropTypes.object.isRequired,
  content: PropTypes.string,
  even: PropTypes.bool.isRequired,
  langKey: PropTypes.string,
};

export default HighlightMobile;
